import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { FaCalendarCheck, FaPhoneVolume, FaComments, FaStar, FaRupeeSign, FaUserClock, FaBell } from 'react-icons/fa';
import { IoTimeOutline } from 'react-icons/io5';
import { BsThreeDotsVertical } from 'react-icons/bs'
import { useAuth } from '../contexts/AuthContext';
import api from '../api';
import { Line } from 'react-chartjs-2';
import { toast } from 'react-toastify';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Home = () => {
  const navigate = useNavigate();
  const { token } = useAuth();

  const [profile, setProfile] = useState(null);
  const [appointments, setAppointments] = useState([]);
  const [totalAppointments, setTotalAppointments] = useState(0);
  const [error, setError] = useState(null);
  const [isOnline, setIsOnline] = useState(false);
  const [isAvailableForCall, setIsAvailableForCall] = useState(false);
  const [isAvailableForChat, setIsAvailableForChat] = useState(false);
  const [loading, setLoading] = useState(true);

  // Chart data
  const data = {
    labels: ['0%', '7%', '9%', '11%', '13%', '15%', '17%', '19%', '21%'],
    datasets: [{
      label: 'Paid Conversion',
      data: [0, 0.3, 0.6, 0.8, 1.0, 1.2, 1.8, 2.1, 2.5],
      fill: false,
      backgroundColor: '#4F46E5',
      borderColor: '#4F46E5',
    }],
  };

  const options = {
    responsive: true,
    plugins: {
      legend: { display: false },
    },
    scales: {
      x: {
        title: {
          display: true,
          text: 'Percentage (%)',
        },
        ticks: {
          autoSkip: true,
          maxRotation: 45,
          minRotation: 0,
          font: { size: 10 },
        },
      },
      y: {
        title: {
          display: true,
          text: 'Value',
        },
        beginAtZero: true,
        ticks: {
          font: { size: 10 },
        },
      },
    },
  };

  const updateAvailability = async (type, value) => {
    try {
      let endpoint = '';
      let payload = {};

      switch (type) {
        case 'online':
          endpoint = '/astrologers/availability';
          payload = { availability: value ? 'Yes' : 'No' };
          break;
        case 'call':
          endpoint = '/astrologers/call-availability';
          payload = { availableForCall: value };
          break;
        case 'chat':
          endpoint = '/astrologers/chat-availability';
          payload = { availableForChat: value };
          break;
        default:
          return;
      }

      const response = await api.patch(endpoint, payload, {
        headers: { Authorization: `Bearer ${token}` }
      });

      if (type === 'online') setIsOnline(value);
      else if (type === 'call') setIsAvailableForCall(value);
      else if (type === 'chat') setIsAvailableForChat(value);

      toast.success(`${type.charAt(0).toUpperCase() + type.slice(1)} status updated successfully`);
    } catch (error) {
      console.error(`Error updating ${type} availability:`, error);
      toast.error(`Failed to update ${type} status`);
    }
  };

  const fetchProfile = async () => {
    try {
      const response = await api.get('/astrologers/profile', {
        headers: { Authorization: `Bearer ${token}` }
      });
      setProfile(response.data);
      setIsOnline(response.data.availability);
      setIsAvailableForCall(response.data.availableForCall);
      setIsAvailableForChat(response.data.availableForChat);
    } catch (error) {
      setError("Failed to fetch profile");
      toast.error("Failed to load profile data");
    }
  };

  const fetchAppointments = async () => {
    try {
      const response = await api.get("/appointment/get-appointments", {
        headers: { Authorization: `Bearer ${token}` }
      });
      setAppointments(response.data.appointments);
      setTotalAppointments(response.data.total);
    } catch (error) {
      console.error("Error fetching appointments:", error);
      if (error.response?.status === 401) navigate("/login");
    }
  };

  useEffect(() => {
    const loadData = async () => {
      setLoading(true);
      await Promise.all([fetchProfile(), fetchAppointments()]);
      setLoading(false);
    };
    loadData();
  }, []);

  if (loading) {
    return (
      <div className="min-h-screen flex items-center justify-center">
        <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-purple-500"></div>
      </div>
    );
  }

  return (
    <>
      <ToastContainer position="top-right" />
      <div className="min-h-screen bg-gray-50">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-6">
          {/* Profile Header */}
          <div className="bg-white rounded-2xl shadow-lg overflow-hidden mb-8">
            <div className="p-1">
              <div className="bg-white p-6">
                <div className="flex flex-col md:flex-row items-center gap-6">
                  <div className="relative">
                    <img
                      src={`https://astrologerdaddy.com/api/${profile?.profileImage}`}
                      alt={profile?.displayName}
                      className="w-24 h-24 rounded-full object-cover border-4 border-white shadow-lg"
                    />
                    <div className={`absolute bottom-0 right-0 w-4 h-4 rounded-full ${isOnline ? 'bg-green-400' : 'bg-gray-400'} border-2 border-white`}></div>
                  </div>
                  <div className="flex-1">
                    <div className="space-y-1">
                      <h1 className="text-3xl font-bold text-gray-900">
                        {profile?.displayName || "Loading..."}
                        <span className="text-sm text-gray-500 ml-2">(visible to customers)</span>
                      </h1>
                      <h2 className="text-xl font-semibold text-gray-700">
                        {profile?.name || "Loading..."}
                      </h2>
                    </div>
                    <p className="text-gray-600 mt-3">
                      {profile?.specializations?.map(spec => spec.name).join(" • ")}
                    </p>
                    <div className="flex flex-wrap gap-4 mt-3">
                      <div className="flex items-center gap-1 text-yellow-500">
                        <FaStar />
                        <span className="text-gray-700">{profile?.averageRating?.toFixed(1)} ({profile?.reviews?.length} reviews)</span>
                      </div>
                      <div className="flex items-center gap-1 text-purple-500">
                        <IoTimeOutline />
                        <span className="text-gray-700">{profile?.experience}+ years exp.</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Availability Controls */}
          <div className="bg-white rounded-2xl shadow-lg p-6 mb-8">
            <h2 className="text-xl font-semibold mb-6">Availability Settings</h2>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
              {/* Online Status */}
              <div className="flex flex-col gap-2">
                <label className="text-sm font-medium text-gray-700">Online Status</label>
                <button
                  onClick={() => updateAvailability('online', !isOnline)}
                  className={`flex items-center justify-center gap-2 px-4 py-2 rounded-lg transition-all
                    ${isOnline ? 'bg-green-500 hover:bg-green-600 text-white' : 'bg-gray-200 hover:bg-gray-300 text-gray-700'}`}
                >
                  <div className={`w-3 h-3 rounded-full ${isOnline ? 'bg-white' : 'bg-gray-500'}`} />
                  {isOnline ? 'Online' : 'Offline'}
                </button>
              </div>

              {/* Call Availability */}
              <div className="flex flex-col gap-2">
                <label className="text-sm font-medium text-gray-700">Call Availability</label>
                <button
                  onClick={() => updateAvailability('call', !isAvailableForCall)}
                  className={`flex items-center justify-center gap-2 px-4 py-2 rounded-lg transition-all
                    ${isAvailableForCall ? 'bg-blue-500 hover:bg-blue-600 text-white' : 'bg-gray-200 hover:bg-gray-300 text-gray-700'}`}
                >
                  <FaPhoneVolume />
                  {isAvailableForCall ? 'Available for Calls' : 'Unavailable for Calls'}
                </button>
              </div>

              {/* Chat Availability */}
              <div className="flex flex-col gap-2">
                <label className="text-sm font-medium text-gray-700">Chat Availability</label>
                <button
                  onClick={() => updateAvailability('chat', !isAvailableForChat)}
                  className={`flex items-center justify-center gap-2 px-4 py-2 rounded-lg transition-all
                    ${isAvailableForChat ? 'bg-purple-500 hover:bg-purple-600 text-white' : 'bg-gray-200 hover:bg-gray-300 text-gray-700'}`}
                >
                  <FaComments />
                  {isAvailableForChat ? 'Available for Chat' : 'Unavailable for Chat'}
                </button>
              </div>
            </div>
          </div>

          {/* Statistics Grid */}
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6 mb-8">
            {/* Today's Available Time */}
            <div className="bg-white p-6 rounded-2xl shadow-lg hover:shadow-xl transition-shadow">
              <div className="flex flex-col">
                <p className="text-gray-600 text-sm">Today's Available Time</p>
                <p className="text-2xl font-bold text-gray-800 mt-2">0hr 0min 0sec</p>
              </div>
            </div>

            {/* Average Time */}
            <div className="bg-white p-6 rounded-2xl shadow-lg hover:shadow-xl transition-shadow">
              <div className="flex flex-col">
                <p className="text-gray-600 text-sm">Average Time (7 days)</p>
                <p className="text-2xl font-bold text-gray-800 mt-2">0hr 0min 0sec</p>
              </div>
            </div>

            {/* Today's Sessions */}
            <div className="bg-white p-6 rounded-2xl shadow-lg hover:shadow-xl transition-shadow">
              <div className="flex flex-col">
                <p className="text-gray-600 text-sm">Today's Sessions</p>
                <p className="text-2xl font-bold text-gray-800 mt-2">0</p>
              </div>
            </div>

            {/* Monthly Sessions */}
            <div className="bg-white p-6 rounded-2xl shadow-lg hover:shadow-xl transition-shadow">
              <div className="flex flex-col">
                <p className="text-gray-600 text-sm">Last 30 Days Sessions</p>
                <p className="text-2xl font-bold text-gray-800 mt-2">30</p>
              </div>
            </div>
          </div>

          {/* Points & Charts Grid */}
          <div className="grid grid-cols-1 md:grid-cols-3 gap-6 mb-8">
            {/* Points to Remember */}
            <div className="bg-white p-6 rounded-2xl shadow-lg">
              <h2 className="text-xl font-semibold mb-4">Points To Remember</h2>
              <ul className="space-y-3">
                <li className="flex items-center">
                  <span className="text-green-500 mr-2">✔</span>
                  <span className="text-gray-700">Maintain empathy, don't harass the user</span>
                </li>
                <li className="flex items-center">
                  <span className="text-green-500 mr-2">✔</span>
                  <span className="text-gray-700">Never share your personal details</span>
                </li>
                <li className="flex items-center">
                  <span className="text-green-500 mr-2">✔</span>
                  <span className="text-gray-700">Never defame the company or other astrologer</span>
                </li>
                <li className="flex items-center">
                  <span className="text-green-500 mr-2">✔</span>
                  <span className="text-gray-700">Don't predict or suggest which is against Indian law</span>
                </li>
              </ul>
            </div>

            {/* Session Time Details */}
            <div className="bg-white p-6 rounded-2xl shadow-lg">
              <div className="flex justify-between items-center mb-4">
                <h2 className="text-xl font-semibold">Session Time Details</h2>
                <span className="text-sm text-gray-500">(last 30 days)</span>
              </div>
              <div className="space-y-4">
                <div className="border border-orange-500 p-4 rounded-lg">
                  <p className="text-xl font-bold text-gray-800">6hr 45min 16sec</p>
                  <p className="text-sm text-gray-600">Total Session Time</p>
                </div>
                <div className="flex justify-center">
                  <span className="text-2xl text-gray-400">=</span>
                </div>
                <div className="border border-orange-500 p-4 rounded-lg">
                  <p className="text-xl font-bold text-gray-800">4hr 30min 10sec</p>
                  <p className="text-sm text-gray-600">Call Session Time</p>
                </div>
                <div className="flex justify-center">
                  <span className="text-2xl text-gray-400">+</span>
                </div>
                <div className="border border-orange-500 p-4 rounded-lg">
                  <p className="text-xl font-bold text-gray-800">2hr 15min 06sec</p>
                  <p className="text-sm text-gray-600">Chat Session Time</p>
                </div>
              </div>
            </div>

            {/* Charts */}
            <div className="bg-white p-6 rounded-2xl shadow-lg">
              <div className="space-y-6">
                <div>
                  <h2 className="text-xl font-semibold mb-4">Paid Conversion</h2>
                  <Line data={data} options={options} />
                </div>{/* Second chart section */}
                <div>
                  <h2 className="text-xl font-semibold mb-4">Paid Duration</h2>
                  <Line data={data} options={options} />
                </div>
              </div>
            </div>
          </div>

          {/* Pricing Information */}
          <div className="bg-white rounded-2xl shadow-lg p-6 mb-8">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              <div className="flex items-center gap-3 p-4 bg-gray-50 rounded-xl">
                <FaRupeeSign className="text-2xl text-gray-600" />
                <div>
                  <p className="text-sm text-gray-600">Price per minute</p>
                  <p className="text-xl font-semibold text-gray-800">₹{profile?.minutePrice}</p>
                </div>
              </div>
              <div className="flex items-center gap-3 p-4 bg-gray-50 rounded-xl">
                <FaUserClock className="text-2xl text-gray-600" />
                <div>
                  <p className="text-sm text-gray-600">Minimum session duration</p>
                  <p className="text-xl font-semibold text-gray-800">{profile?.minimumCallDuration} mins</p>
                </div>
              </div>
            </div>
          </div>

          {/* Previous Appointments */}
          <div className="bg-white rounded-2xl shadow-lg p-6">
            <div className="flex justify-between items-center mb-6">
              <h2 className="text-xl font-semibold">Previous Appointments</h2>
              <button
                onClick={() => navigate('/astrologer/appointments')}
                className="text-purple-600 hover:text-purple-700 font-medium transition-colors"
              >
                View All
              </button>
            </div>

            <div className="space-y-4">
              {appointments.length > 0 ? (
                appointments.slice(0, 5).map((appointment) => (
                  <div
                    key={appointment._id}
                    className="border border-gray-100 p-4 rounded-xl hover:bg-gray-50 transition-all"
                  >
                    <div className="flex flex-col sm:flex-row justify-between gap-4">
                      <div className="flex items-start gap-3">
                        <div className="w-12 h-12 rounded-full bg-gray-100 flex items-center justify-center">
                          <FaUserClock className="text-gray-500" />
                        </div>
                        <div>
                          <h3 className="font-medium text-gray-800">
                            {`${appointment.user.firstName} ${appointment.user.lastName}`}
                          </h3>
                          <div className="flex flex-wrap gap-3 mt-1 text-sm text-gray-600">
                            <span>{new Date(appointment.date).toLocaleDateString()}</span>
                            <span>{appointment.time}</span>
                            <span>{appointment.duration} mins</span>
                          </div>
                        </div>
                      </div>
                      <div className="flex items-center gap-3">
                        <span className={`px-3 py-1 rounded-full text-sm font-medium
                          ${appointment.type === 'call'
                            ? 'bg-green-100 text-green-800'
                            : 'bg-blue-100 text-blue-800'}`}
                        >
                          {appointment.type.charAt(0).toUpperCase() + appointment.type.slice(1)}
                        </span>
                        <button className="text-gray-400 hover:text-gray-600 transition-colors">
                          <BsThreeDotsVertical />
                        </button>
                      </div>
                    </div>
                  </div>
                ))
              ) : (
                <div className="text-center py-8">
                  <p className="text-gray-500">No appointments found</p>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default React.memo(Home);