import React, { useState, useEffect } from "react";
import { useAuth } from "../contexts/AuthContext";
import api from "../api";
import { useNavigate, Link } from "react-router-dom";
import dayjs from "dayjs";
import Swal from "sweetalert2";
import { useWebSocket } from "../contexts/WebSocketContext";
import { AiOutlineLoading } from "react-icons/ai"; // For loading spinner

const Appointments = () => {
  const { token } = useAuth();
  const ws = useWebSocket();
  const [appointments, setAppointments] = useState([]);
  const [loading, setLoading] = useState(true);
  const [wsLoading, setWsLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [search, setSearch] = useState("");

  const [statusFilter, setStatusFilter] = useState("all");
  const [acceptedFilter, setAcceptedFilter] = useState("all");
  const [sortOrder, setSortOrder] = useState("desc");
  const [total, setTotal] = useState(0);

  const navigate = useNavigate();

  const fetchAppointments = async () => {
    setLoading(true);
    try {
      const response = await api.get("/appointment/get-appointments", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          page,
          limit,
          search,
          sortOrder,
          statusFilter,
          acceptedFilter,
        },
      });
      setAppointments(response.data.appointments);
      setTotal(response.data.total);
    } catch (error) {
      console.error("Error fetching appointments:", error);
      if (error.response && error.response.status === 401) {
        navigate("/login");
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchAppointments();

    if (ws) {
      setWsLoading(false);

      ws.onmessage = (event) => {
        const data = JSON.parse(event.data);
        console.log("Received WebSocket message:", data);

        if (
          data.type === "NEW_APPOINTMENT" &&
          data.appointmentId &&
          data.userId
        ) {
          Swal.fire({
            title: "New Appointment Request",
            text: `You have a new appointment request for ${data.duration} minutes.`,
            icon: "info",
            showCancelButton: true,
            confirmButtonText: "Accept",
            cancelButtonText: "Reject",
            confirmButtonColor: "#7f1d1d",
            reverseButtons: true,
          }).then((result) => {
            if (result.isConfirmed) {
              handleAccept(data.appointmentId);
            } else if (result.dismiss === Swal.DismissReason.cancel) {
              handleReject(data.appointmentId);
            }
          });

          fetchAppointments();
        }
      };

      ws.onopen = () => setWsLoading(false);
      ws.onclose = () => setWsLoading(true);
    }
  }, [token, page, limit, search, sortOrder, statusFilter, acceptedFilter, ws]);

  const handleAccept = async (id) => {
    setAppointments((prev) =>
      prev.map((appointment) =>
        appointment._id === id
          ? { ...appointment, accepted: "Accepted" }
          : appointment
      )
    );

    try {
      const response = await api.post(
        `/appointment/${id}/accept-reject`,
        { accepted: "Accepted" },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.data.call) {
        Swal.fire({
          icon: "success",
          title: "Appointment Accepted",
          text: "Joining the call.",
          showConfirmButton: false,
          timer: 1000,
        }).then(() => {
          // redirect user to either appointments page or chat page

          navigate(`/astrologer/appointment/call/${response.data.call.id}`);
        });
      } else {
        Swal.fire({
          icon: "success",
          title: "Appointment Accepted",
          text: "Joining the chat.",
          showConfirmButton: false,
          timer: 1000,
        }).then(() => {
          navigate(`/astrologer/appointment/chat/${response.data.chat.id}`);
        });
      }
      fetchAppointments();
    } catch (error) {
      console.error("Error updating appointment status:", error);
      setAppointments((prev) =>
        prev.map((appointment) =>
          appointment._id === id
            ? { ...appointment, accepted: "Pending" }
            : appointment
        )
      );
    }
  };

  const handleReject = async (id) => {
    setAppointments((prev) =>
      prev.map((appointment) =>
        appointment._id === id
          ? { ...appointment, accepted: "Rejected" }
          : appointment
      )
    );

    try {
      await api.post(
        `/appointment/${id}/accept-reject`,
        { accepted: "Rejected" },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      fetchAppointments();
    } catch (error) {
      console.error("Error updating appointment status:", error);
      setAppointments((prev) =>
        prev.map((appointment) =>
          appointment._id === id
            ? { ...appointment, accepted: "Pending" }
            : appointment
        )
      );
    }
  };

  const renderAppointments = () => {
    if (appointments.length === 0) {
      return (
        <p className="text-center text-gray-600">No appointments found.</p>
      );
    }
    return (
      <>
        {appointments.map((appointment) => (
          <div
            key={appointment._id}
            className="bg-white shadow-lg rounded-lg p-6 mb-8 border border-gray-200 hover:shadow-2xl transition-shadow duration-300 ease-in-out transform hover:-translate-y-1"
          >
            <div className="flex flex-col md:flex-row md:space-x-8">
              <div className="flex-1">
                <h3 className="text-xl font-bold text-gray-900 mb-6 border-b pb-2">
                  Call Details
                </h3>
                <div className="space-y-4 mb-6">
                  <p className="text-sm text-gray-700">
                    <span className="font-semibold text-gray-900">Date:</span>{" "}
                    {dayjs(appointment.date).format("DD/MM/YYYY")},{" "}
                    {appointment.time}
                  </p>

                  <p className="text-sm text-gray-700">
                    <span className="font-semibold text-gray-900">
                      Duration:
                    </span>{" "}
                    {appointment.duration} minutes
                  </p>
                </div>

                {appointment.status === "Pending" &&
                  appointment.accepted === "Accepted" &&
                  (appointment.type === "call" ? (
                    <Link
                      to={`/astrologer/appointment/call/${appointment._id}`}
                      className="text-sm font-semibold bg-orange-600 hover:bg-orange-500 px-4 py-2 rounded-lg text-white inline-block mb-4 transition-all duration-300 ease-in-out transform hover:scale-105"
                    >
                      Rejoin Call
                    </Link>
                  ) : (
                    <Link
                      to={`/astrologer/appointment/chat/${appointment._id}`}
                      className="text-sm font-semibold bg-orange-600 hover:bg-orange-500 px-4 py-2 rounded-lg text-white inline-block mb-4 transition-all duration-300 ease-in-out transform hover:scale-105"
                    >
                      Rejoin Chat
                    </Link>
                  ))}

                {/* Wrap Call Completion and Call Status */}
                <div className="flex flex-col justify-between space-y-4">
                  <p
                    className={`text-sm font-semibold ${appointment.status === "Pending"
                        ? "text-yellow-600"
                        : appointment.status === "Completed"
                          ? "text-green-600"
                          : "text-red-600"
                      }`}
                  >
                    Completion Status: {appointment.accepted}
                  </p>

                  <p
                    className={`text-sm font-semibold ${appointment.accepted === "Pending"
                        ? "text-yellow-600"
                        : appointment.accepted === "Accepted"
                          ? "text-green-600"
                          : "text-red-600"
                      }`}
                  >
                    Call Status: {appointment.status}
                  </p>
                </div>
              </div>

              <div className="flex-1 mt-6 md:mt-0">
                <h3 className="text-xl font-bold text-gray-900 mb-6 border-b pb-2">
                  User Details
                </h3>
                <div className="space-y-4">
                  <p className="text-sm text-gray-700">
                    <span className="font-semibold text-gray-900">
                      Requested By:
                    </span>{" "}
                    {appointment.user.firstName} {appointment.user.lastName}
                  </p>

                  <p className="text-sm text-gray-700">
                    <span className="font-semibold text-gray-900">DOB:</span>{" "}
                    {dayjs(appointment.user.dob).format("DD/MM/YYYY")}
                  </p>

                  {/* Align with Call Completion and Call Status */}
                  <div className="flex flex-col justify-between space-y-4">
                    <p className="text-sm text-gray-700">
                      <span className="font-semibold text-gray-900">
                        Birth Time:
                      </span>{" "}
                      {appointment.user.birthTime}
                    </p>

                    <p className="text-sm text-gray-700">
                      <span className="font-semibold text-gray-900">
                        Birth Place:
                      </span>{" "}
                      {appointment.user.birthPlace}
                    </p>
                  </div>
                </div>
              </div>
            </div>

            {appointment.accepted === "Pending" && (
              <div className="flex space-x-4 mt-6">
                <button
                  onClick={() => handleAccept(appointment._id)}
                  className="bg-green-600 hover:bg-green-700 text-white font-semibold py-2 px-6 rounded-lg transition-all duration-300 ease-in-out transform hover:scale-105"
                  disabled={loading}
                >
                  Accept
                </button>
                <button
                  onClick={() => handleReject(appointment._id)}
                  className="bg-red-600 hover:bg-red-700 text-white font-semibold py-2 px-6 rounded-lg transition-all duration-300 ease-in-out transform hover:scale-105"
                  disabled={loading}
                >
                  Reject
                </button>
              </div>
            )}
          </div>
        ))}
      </>
    );
  };

  return (
    <div className="max-w-5xl mx-auto my-12 p-6 bg-gray-50 rounded-lg shadow-lg">
      <h1 className="text-4xl font-bold text-center text-gray-800 mb-8">
        Appointments
      </h1>
      <div className="flex flex-col md:flex-row justify-end mb-6">
        <div className="flex flex-col md:flex-row md:space-x-4">
          <select
            value={statusFilter}
            onChange={(e) => setStatusFilter(e.target.value)}
            className="border border-gray-300 rounded-lg p-3 bg-white"
          >
            <option value="all">All Status</option>
            <option value="Pending">Pending</option>
            <option value="Completed">Completed</option>
            <option value="Failed">Failed</option>
          </select>
          <select
            value={acceptedFilter}
            onChange={(e) => setAcceptedFilter(e.target.value)}
            className="border border-gray-300 rounded-lg p-3 bg-white"
          >
            <option value="all">All Accept Status</option>
            <option value="Pending">Pending</option>
            <option value="Accepted">Accepted</option>
            <option value="Rejected">Rejected</option>
          </select>

          <select
            value={sortOrder}
            onChange={(e) => setSortOrder(e.target.value)}
            className="border border-gray-300 rounded-lg p-3 bg-white"
          >
            <option value="desc">Latest</option>
            <option value="asc">Oldest</option>
          </select>
        </div>
      </div>
      {loading ? (
        <div className="flex justify-center items-center">
          <AiOutlineLoading className="animate-spin h-8 w-8 text-red-800" />
          <p className="text-center text-gray-600 ml-2">
            Loading appointments...
          </p>
        </div>
      ) : (
        renderAppointments()
      )}
      {wsLoading && (
        <div className="flex justify-center items-center mt-4">
          <AiOutlineLoading className="animate-spin h-5 w-5 text-gray-600" />
          <p className="text-center text-gray-600 ml-2">
            Reconnecting WebSocket...
          </p>
        </div>
      )}
      <div className="flex justify-between mt-6">
        <button
          disabled={page === 1}
          onClick={() => setPage(page - 1)}
          className={`px-4 py-2 mx-1 text-white bg-orange-800 rounded disabled:bg-gray-400${page === 1 ? "cursor-not-allowed opacity-50" : "hover:bg-orange-500"
            }`}
        >
          Previous
        </button>
        <button
          disabled={page * limit >= total}
          onClick={() => setPage(page + 1)}
          className={`px-4 py-2 mx-1 text-white bg-orange-800 rounded disabled:bg-gray-400 ${page * limit >= total
              ? "cursor-not-allowed opacity-50"
              : "hover:bg-orange-500"
            }`}
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default Appointments;
