import React from "react";

const TermsAndConditions = () => {
  return (
    <>
      <div className="p-6 my-4 lg:my-12 max-w-3xl mx-auto bg-white shadow-md rounded-lg">
        <h1 className="text-3xl text-center font-bold mb-4 text-gray-800">
          Terms & Conditions
        </h1>

        <p className="mb-6 text-gray-700">
          These terms are established between astrologers who register as advisors ("Astrologers")
          with BK ASTRO SERVICE PRIVATE LIMITED (hereinafter referred to as "BK Astro"). By signing
          up and clicking the "I AGREE" button during registration, astrologers confirm their agreement
          to these Terms of Service. Acceptance signifies a binding legal relationship between the astrologer 
          and our platform upon approval of their registration.
        </p>

        <p className="mb-6 text-gray-700">
          BK Astro is the author and operator of its website and associated applications. References of 
          this website are," "we," "us," "our" or Astrologer Daddy include BK Astro and its affiliates.
        </p>

        <h2 className="text-2xl font-semibold mb-4 text-gray-800">
          1. Nature and Applicability of the Terms
        </h2>

        <div className="mb-6">
          <h3 className="text-xl font-semibold mb-2 text-gray-800">
            a. Definition of Terms
          </h3>
          <p className="mb-4 text-gray-700 pl-4">
            The agreement includes these Terms and Conditions and the Privacy Policy, collectively
            referred to as "Terms." These Terms govern the relationship between BK Astro and its astrologers.
          </p>

          <h3 className="text-xl font-semibold mb-2 text-gray-800">
            b. Definition of Astrologer
          </h3>
          <p className="mb-4 text-gray-700 pl-4">
            An "Astrologer" is an individual who represents a valid degree, diploma, or certificate
            relevant to their registration category. Upon successful approval of their registration form
            by us, astrologers can provide consultation services through the platform. Registration may 
            be declined for incomplete forms, closed categories, or other reasons. Astrologers bear 
            responsibility for the authenticity of their submitted qualifications. Misrepresentation may 
            result in profile termination.
          </p>

          <h3 className="text-xl font-semibold mb-2 text-gray-800">
            c. Modification of Terms
          </h3>
          <p className="mb-4 text-gray-700 pl-4">
            We reserves the right to update, modify, or remove terms and will notify astrologers via
            email, website notifications, or other channels. Continued use of the platform signifies
            acceptance of these changes.
          </p>

          <h3 className="text-xl font-semibold mb-2 text-gray-800">
            d. Binding Nature
          </h3>
          <p className="mb-4 text-gray-700 pl-4">
            These Terms, along with any communicated updates, are binding on both parties.
          </p>

          <h3 className="text-xl font-semibold mb-2 text-gray-800">
            e. Terms of Engagement
          </h3>
          <p className="mb-4 text-gray-700 pl-4">
            Upon signing up:
            <br />i. Astrologers must upload a current profile picture, which cannot be changed without approval. 
            This picture is exclusive to us and may not be used elsewhere. Violations may result in deactivation 
            and penalties.
            <br />ii. Astrologers agree not to reveal their personal identity and must use the assigned display 
            name for all platform interactions.
            <br />iii. Astrologers must not publicize their work without prior approval.
            <br />iv. Specific identity and profile details cannot be reused on competitor platforms or personal 
            media for five years after contract termination.
            <br />v. Misrepresentation of information will incur penalties and immediate deactivation of profiles.
          </p>

          <h3 className="text-xl font-semibold mb-2 text-gray-800">
            f. Restrictions on Competition
          </h3>
          <p className="mb-4 text-gray-700 pl-4">
            Astrologers are prohibited from offering services or launching competitor platforms during 
            their engagement and for five years following contract termination.
          </p>

          <h3 className="text-xl font-semibold mb-2 text-gray-800">
            g. Profile Retention
          </h3>
          <p className="mb-4 text-gray-700 pl-4">
            BK Astro reserves the right to display inactive astrologer profiles for up to three years 
            unless deactivated due to violations. The following guidelines outline the conditions under 
            which profiles may remain visible and the reasons for deactivation:
            <br /><br />
            1. Inactive Profiles
            <br />○ Astrologer profiles that have not been active for a period of six months or more will 
            be categorized as inactive. These profiles will still be visible to users, unless the astrologer 
            opts to deactivate their profile.
            <br />○ Profiles will be removed from public view only when the astrologer explicitly requests 
            to be deactivated or if they fail to comply with the platform's guidelines.
            <br /><br />
            2. Duration of Profile Retention
            <br />○ We retains the right to keep inactive profiles visible for up to three years from the 
            last active date. This retention period allows users to view and interact with astrologers they 
            may have previously consulted.
            <br />○ After the three-year period, inactive profiles will be permanently removed from the platform, 
            unless the astrologer resumes activity or the profile is reinstated by request.
            <br /><br />
            3. Deactivation Due to Violations
            <br />○ If an astrologer's profile is found to violate any of our terms, policies, or ethical 
            guidelines, the profile may be deactivated immediately. Violations may include unethical behavior, 
            user complaints, or failure to adhere to community standards.
            <br />○ Deactivated profiles due to violations will not be visible to users, and the astrologer 
            may not be able to reactivate the profile unless the issue is resolved in accordance with platform 
            policies.
            <br /><br />
            4. Profile Reactivation
            <br />○ Astrologers may request reactivation of their profile after a deactivation or inactivity 
            period, subject to review and approval by BK Astro. Reactivation will only occur if the astrologer 
            complies with all necessary platform requirements.
            <br />○ If an astrologer's profile was deactivated due to violations, reactivation will only be 
            considered if the astrologer provides proof of corrective actions taken and adheres to the platform's 
            guidelines moving forward.
            <br /><br />
            5. User Transparency
            <br />○ BK Astro aims to maintain transparency for users by clearly indicating the status of each 
            astrologer's profile (active, inactive, or deactivated). This ensures users have a clear understanding 
            of the availability of the astrologer they are consulting.
            <br /><br />
            By continuing to use services, astrologers acknowledge and accept the platform's profile retention 
            policy, including the right to retain or deactivate profiles as per the conditions above.
          </p>

          <h3 className="text-xl font-semibold mb-2 text-gray-800">
            h. Probation and Resignation Terms
          </h3>
          <p className="mb-4 text-gray-700 pl-4">
            Astrologers may not resign within the initial three-month probation period. Early resignation 
            incurs a recovery fee of INR 100,000. Post-probation, one month's notice is required, failing 
            which a penalty of INR 41,000 will apply.
          </p>
        </div>

        <h2 className="text-2xl font-semibold mb-4 text-gray-800">
          2. Limitation of Liability
        </h2>
         <p className="mb-6 text-gray-700">
          BK Astro and its affiliates, officers, employees, and agents are not liable for any direct,
          indirect, or consequential damages arising from platform use or user interactions. In the
          event of legal limitations, our maximum liability will not exceed INR 500 or $10, whichever
          is higher. These limitations survive the termination of the agreement.
        </p>


        <div className="mb-6">
          <h3 className="text-xl font-semibold mb-2 text-gray-800">
            a. Astrologer Agreement
          </h3>
          <p className="mb-4 text-gray-700">The Astrologer agrees to the following:</p>

          <div className="pl-4">
            <h4 className="text-lg font-semibold mb-2">i. Minimum Eligibility Criteria:</h4>
            <p className="mb-4 text-gray-700">
              The astrologer must be at least 18 years of age or legally recognized as an entity. If representing another individual or legal entity, the astrologer must have proper authorization to submit the registration form and bind the individual or entity they represent.
            </p>

            <h4 className="text-lg font-semibold mb-2">ii. Accurate Information:</h4>
            <p className="mb-4 text-gray-700">
              The astrologer is obligated to provide truthful and accurate details about their qualifications, skills, degrees, and professional background. This information must be kept updated during the entire duration of the agreement to ensure it remains correct and reliable.
            </p>

            <h4 className="text-lg font-semibold mb-2">iii. Professional Competence:</h4>
            <p className="mb-4 text-gray-700">
              Astrologers must maintain their skills and qualifications, including their status as licensed professionals in any relevant jurisdiction. They must comply with local regulations applicable to their practice.
            </p>

            <div className="pl-4">
              <p className="mb-2">1. Continuous Education: Astrologers are expected to engage in ongoing education and training to stay updated with the latest astrological techniques, trends, and best practices. This ensures they provide accurate, informed, and ethical guidance to clients.</p>
              <p className="mb-2">2. Ethical Standards: Practitioners must adhere to a recognized code of ethics in astrology. They should operate with honesty, transparency, and integrity in their interactions with clients, ensuring that their services are delivered with professionalism and respect.</p>
              <p className="mb-2">3. Confidentiality: Astrologers must respect the privacy of their clients by keeping all personal and session-related information confidential. Sharing client details or reading information without explicit consent is prohibited, except when required by law.</p>
              <p className="mb-2">4. Clear Communication: Astrologers must clearly explain their services, methods, and any potential limitations of astrology, ensuring clients understand what to expect. They should also inform clients about the nature of astrology and provide proper disclaimers where necessary.</p>
              <p className="mb-2">5. License and Registration: In jurisdictions that require licensing, astrologers must maintain the appropriate certifications or registrations as per local laws. This might include completing periodic renewals or updates to their professional qualifications.</p>
              <p className="mb-2">6. Professional Boundaries: Astrologers should establish clear professional boundaries with clients, ensuring their role remains supportive and not exploitative. They should not offer services outside the scope of their expertise, such as medical, legal, or financial advice, unless they have the relevant qualifications.</p>
              <p className="mb-2">7. Cultural Sensitivity: Astrologers should be mindful of cultural differences and respect the values, beliefs, and traditions of their clients. They should offer astrological insights in a manner that is inclusive and non-judgmental.</p>
              <p className="mb-2">8. Transparency in Fees and Services: It is essential that astrologers provide transparent pricing for their services and communicate any additional charges clearly before proceeding with consultations. Clients should never be surprised by unexpected fees.</p>
              <p className="mb-2">9. Client Consent: Before offering any astrological services, astrologers must obtain informed consent from their clients. This includes making sure the client understands the nature of the consultation and the possible outcomes of the reading.</p>
            </div>

            <h4 className="text-lg font-semibold mb-2">iv. Legal Restrictions:</h4>
            <p className="mb-4 text-gray-700">
              Astrologers are prohibited from offering services or advice in regions where they lack authorization, licensing, or good standing.
            </p>

            <h4 className="text-lg font-semibold mb-2">v. Medical Disclaimer:</h4>
            <div className="pl-4">
              <p className="mb-2">1. No Medical Diagnosis or Treatment: Astrologers are not licensed healthcare professionals and should not be regarded as medical experts. They do not have the qualifications to diagnose, treat, or prescribe medication for any physical, mental, or emotional health condition. Astrological services are not a substitute for professional medical care or treatment.</p>
              <p className="mb-2">2. Health Concerns and Medical Issues: Clients seeking advice on medical conditions or physical symptoms should consult a licensed medical professional or healthcare provider. Any information provided during astrology sessions regarding health matters should be considered as general advice and not as a medical recommendation.</p>
              <p className="mb-2">3. Role of Astrology in Health Matters: Astrology can offer insights into personal challenges and emotional patterns, which may indirectly affect overall well-being. However, it should not be used as a primary tool for addressing or managing health issues, medical treatments, or conditions requiring clinical care.</p>
              <p className="mb-2">4. Encouragement to Seek Professional Medical Help: Clients are encouraged to seek professional medical help from certified doctors, therapists, or specialists for any concerns regarding physical, mental, or emotional health. Astrologers should not be viewed as a replacement for licensed healthcare providers or therapists.</p>
              <p className="mb-2">5. Clarification of Astrological Guidance: Any guidance given by an astrologer related to health is purely for informational and introspective purposes and should not be construed as medical advice. It is essential to take a holistic approach to health that involves the appropriate professionals and treatments, including consultations with medical practitioners.</p>
              <p className="mb-2">6. Limitations of Astrological Insight: While astrology can provide valuable perspectives on personality, life path, and emotional tendencies, it does not have the scientific foundation to address medical diagnoses or treatment recommendations. Clients should always prioritize their health and well-being by relying on professional medical advice when necessary.</p>
              <p className="mb-2">7.	Cultural Sensitivity: While astrology can provide valuable perspectives on personality, life path, and emotional tendencies, it does not have the scientific foundation to address medical diagnoses or treatment recommendations. Clients should always prioritize their health and well-being by relying on professional medical advice when necessary.</p>
            </div>

            <h4 className="text-lg font-semibold mb-2">vi. Advisory Limitations:</h4>
            <p className="mb-4 text-gray-700">
              Astrologers are required to inform users that their advice is not a substitute for medical guidance and is solely for informational purposes.
            </p>

            <h4 className="text-lg font-semibold mb-2">vii. Prohibited Services:</h4>
            <p className="mb-4 text-gray-700">
              Astrologers affirm that any prohibited services, such as unauthorized pujas, spells, or remedies, are their sole responsibility. Under no circumstances will the company be held liable for such actions.
            </p>
          </div>
        </div>

        <h2 className="text-2xl font-semibold mb-4 text-gray-800">
          3. Dispute Resolution
        </h2>
        <p className="mb-6 text-gray-700 pl-4">
          Any dispute arising in relation to these Terms or the Website shall initially be addressed
          through alternative dispute resolution methods, including but not limited to Arbitration,
          Mediation, and Conciliation.
        </p>

        <div className="mt-8 pt-4 border-t border-gray-200 text-center text-gray-600">
          <p>Last Updated: {new Date().toLocaleDateString()}</p>
          <p>© {new Date().getFullYear()} BK ASTRO SERVICE PRIVATE LIMITED. All rights reserved.</p>
        </div>
      </div>
    </>
  );
};

export default TermsAndConditions;