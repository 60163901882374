import React, { useState, useEffect } from "react";
import {
    FaImage,
    FaVideo,
    FaEdit,
    FaSave,
    FaTrash,
} from "react-icons/fa";
import api from "../api";
import { useAuth } from "../contexts/AuthContext";

function Gallery() {
    const { token } = useAuth();
    const [profile, setProfile] = useState(null);
    const [profileImage, setProfileImage] = useState(null);
    const [aboutImages, setAboutImages] = useState([]);
    const [initialProfile, setInitialProfile] = useState(null);
    const [isEditing, setIsEditing] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState("");
    const [success, setSuccess] = useState("");
    const MAX_FILE_SIZE = 5 * 1024 * 1024; // 5MB

    const handleAboutImagesChange = async (e) => {
    const files = e.target.files;
    setError("");
    setSuccess("");

    for (let i = 0; i < files.length; i++) {
        if (files[i].size > MAX_FILE_SIZE) {
            setError("File is too large. Please upload files under 5MB.");
            return;
        }
    }

    const formData = new FormData();
    Array.from(files).forEach((file) => {
        formData.append("aboutImages", file);
    });

    try {
        await api.patch('/astrologers/profile', formData, {
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'multipart/form-data',
            },
        });
        setSuccess("Images uploaded successfully!");
        fetchProfile(); // Refresh profile after upload
    } catch (error) {
        setError(error?.response?.data?.message || "Failed to upload images. Please try again.");
    }
};

    const fetchProfile = async () => {
        try {
            const response = await api.get(`/astrologers/profile`, {
                headers: { Authorization: `Bearer ${token}` },
            });
            setProfile(response.data);
            setInitialProfile(response.data);
        } catch (error) {
            setError("Failed to fetch profile. Please try again later.");
            console.error("Error fetching profile:", error);
        }
    };

    useEffect(() => {
        fetchProfile();
    }, [token]);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setProfile(prev => ({
            ...prev,
            [name]: value,
        }));
    };

    const handleSaveChanges = async () => {
        setIsLoading(true);
        setError("");
        setSuccess("");
        
        const formData = new FormData();
        
        // Append profile changes
        if (profileImage) {
            formData.append("profileImage", profileImage);
        }
        
        // Append other profile fields that have changed
        Object.keys(profile).forEach(key => {
            if (profile[key] !== initialProfile[key]) {
                formData.append(key, profile[key]);
            }
        });

        try {
            await api.patch(`/astrologers/profile`, formData, {
                headers: {
                    Authorization: `Bearer ${token}`,
                    "Content-Type": "multipart/form-data",
                },
            });
            setSuccess("Profile updated successfully.");
            setInitialProfile(profile);
            setIsEditing(false);
            fetchProfile();
        } catch (error) {
            setError(error?.response?.data?.message || "Failed to update profile. Please try again later.");
        } finally {
            setIsLoading(false);
        }
    };

    const handleImageDelete = async (imageName) => {
        try {
            await api.delete(`/astrologers/profile/images`, {
                headers: { Authorization: `Bearer ${token}` },
                data: { imageName },
            });
            setSuccess("Image deleted successfully.");
            fetchProfile();
        } catch (error) {
            setError(error?.response?.data?.message || "Failed to delete image. Please try again later.");
        }
    };

    if (!profile) return <div className="text-center text-gray-600 min-h-36">Loading...</div>;

    return (
        <div className="container mx-auto p-4 sm:p-6 lg:p-8 max-w-4xl">
            <div className="bg-white shadow-lg rounded-lg p-4 sm:p-6 lg:p-8 border border-gray-200">
                <div className="flex flex-col gap-4">
                    <div className="flex-1">
                        {profile.aboutImages?.length > 0 && (
                            <div className="flex gap-2 flex-wrap mx-auto bg-white mt-6 p-6 rounded-lg shadow-lg mb-4">
                                {profile.aboutImages.map((img, index) => (
                                    <div key={index} className="relative">
                                        <img
                                            src={`https://astrologerdaddy.com/api/${img}`}
                                            alt={`About ${index}`}
                                            className="w-full h-32 object-cover rounded border border-gray-300"
                                        />
                                        {isEditing && (
                                            <button
                                                onClick={() => handleImageDelete(img)}
                                                className="absolute top-2 right-2 bg-red-500 text-white rounded-full p-1"
                                            >
                                                <FaTrash />
                                            </button>
                                        )}
                                    </div>
                                ))}
                            </div>
                        )}

                        {isEditing && (
                            <>
                                <div className="mb-4">
                                    <label className="flex items-center mb-2 text-lg font-medium">
                                        <FaImage className="text-gray-600 mr-3" />
                                        About Images:
                                    </label>
                                    <input
                                        type="file"
                                        accept="image/*"
                                        multiple
                                        onChange={handleAboutImagesChange}
                                        className="w-full border rounded p-3"
                                    />
                                </div>

                                <div className="mb-4">
                                    <label className="flex items-center mb-2 text-lg font-medium">
                                        <FaVideo className="text-gray-600 mr-3" />
                                        Promotional Video (Youtube VIDEO ID):
                                    </label>
                                    <input
                                        type="text"
                                        className="w-full border rounded p-3"
                                        onChange={handleInputChange}
                                        name="promotionalVideo"
                                        value={profile.promotionalVideo}
                                        placeholder="https://www.youtube.com/watch?v=your-video-id"
                                    />
                                </div>
                            </>
                        )}

                        <div className="flex gap-4">
                            <button
                                onClick={() => {
                                    setIsEditing(!isEditing);
                                    setError("");
                                    setSuccess("");
                                }}
                                className={`px-4 py-2 flex gap-4 w-fit items-center justify-center text-sm sm:text-base rounded text-white ${isEditing ? "bg-red-600" : "bg-orange-600"} hover:bg-opacity-80`}
                            >
                                <FaEdit /> {isEditing ? "Cancel" : "Edit"}
                            </button>

                            {isEditing && (
                                <button
                                    onClick={handleSaveChanges}
                                    className="px-4 py-2 flex gap-4 w-fit items-center justify-center text-sm sm:text-base bg-orange-600 text-white rounded hover:bg-opacity-80"
                                >
                                    <FaSave />
                                    {isLoading ? "Saving..." : "Save Changes"}
                                </button>
                            )}
                        </div>

                        {error && <div className="text-red-600 mt-4 text-center">{error}</div>}
                        {success && <div className="text-green-600 mt-4 text-center">{success}</div>}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Gallery;