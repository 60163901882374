// import { useState } from "react";
// import api from "../api";
// import { FaTimes } from "react-icons/fa";
// import { useAuth } from "../contexts/AuthContext";
// import { useNavigate } from "react-router-dom";
// import { AiOutlineLoading3Quarters } from "react-icons/ai";

// function WithdrawModal({ onClose }) {
//   const { token } = useAuth();
//   const navigate = useNavigate();
//   const [amount, setAmount] = useState("");
//   const [loading, setLoading] = useState(false);
//   const [error, setError] = useState("");
//   const [success, setSuccess] = useState("");


//   const [name, setName] = useState(""); // State for Name
//   const [IFSC_CODE, setIFSC_CODE] = useState(""); // State for IFSC IFSC_CODE
//   const [bankName, setBankName] = useState(""); // State for Bank Name
//   const [account_number, setaccount_number] = useState(""); // State for Bank A/C Number


//   const handleWithdraw = async () => {
//     setLoading(true);
//     setError("");
//     setSuccess("");

//     try {
//       // const response = await api.post(
//       //   "/withdrawal/settled-payments/request-withdrawal",
//       //   { amount },{name},{IFSC_CODE},{bankName},{account_number} ,
//       //   {
//       //     headers: {
//       //       Authorization: `Bearer ${token}`,
//       //     },
//       //   }
//       // );


//       const response = await api.post(
//         "/withdrawal/settled-payments/request-withdrawal",
//         { 
//           amount, 
//           name, 
//           IFSC_CODE, 
//           bankName, 
//           account_number 
//         },
//         {
//           headers: {
//             Authorization: `Bearer ${token}`,
//           },
//         }
//       );
//       setSuccess("Withdrawal request created successfully");
//       setAmount("");
//     } catch (error) {
//       setError(error.response?.data?.message || "Something went wrong");
//     } finally {
//       setLoading(false);
//     }
//   };

//   return (
//     <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
//       <div className="bg-white p-6 rounded-lg shadow-xl max-w-md w-full relative">
//         <button
//           onClick={onClose}
//           className="absolute top-3 right-3 text-gray-400 hover:text-gray-600 focus:outline-none"
//         >
//           <FaTimes size={18} />
//         </button>
//         <h2 className="text-xl font-semibold mb-2 text-center text-gray-700">
//         Enter Withdrawal Details
//         </h2>
//         <h2 className="text-sm font-semibold mb-4 text-center text-gray-700">
//           Note: 5% TDS Deduction will be applied
//         </h2>
//         <div className="mb-4">
//           {error && <p className="text-red-500 text-center">{error}</p>}
//           {success && <p className="text-green-500 text-center">{success}</p>}
//         </div>
//         <label
//           htmlFor="amount"
//           className="block text-gray-700 font-medium mb-2"
//         >
//           Amount
//         </label>
//         <input
//           type="number"
//           id="amount"
//           value={amount}
//           onChange={(e) => setAmount(e.target.value)}
//           className="w-full border border-gray-400 p-2 rounded-lg"
//           placeholder="Enter amount"
//         />

//         <label
//           htmlFor="name"
//           className="block text-gray-700 mt-2 font-medium mb-2"
//         >
//           Name
//         </label>
//         <input
//           type="text"
//           id="name"
//           value={amount}
//           onChange={(e) => setName(e.target.value)}
//           className="w-full border border-gray-400 p-2 rounded-lg"
//           placeholder="Enter name"
//         />
//         <label
//           htmlFor="IFSC IFSC_CODE"
//           className="block text-gray-700 mt-2 font-medium mb-2"
//         >
//         IFSC IFSC_CODE
//         </label>
//         <input
//           type="number"
//           id="IFSC_CODEt"
//           value={amount}
//           onChange={(e) => setIFSC_CODE(e.target.value)}
//           className="w-full border border-gray-400 p-2 rounded-lg"
//           placeholder="Enter IFSC IFSC_CODE"
//         />
//         <label
//           htmlFor="bank_name"
//           className="block text-gray-700 mt-2 font-medium mb-2"
//         >
//          Bank Name
//         </label>
//         <input
//           type="text"
//           id="bank_name"
//           value={amount}
//           onChange={(e) => setBankName(e.target.value)}
//           className="w-full border border-gray-400 p-2 rounded-lg"
//           placeholder="Enter Bank Name"
//         />
//          <label
//           htmlFor="bank_name"
//           className="block text-gray-700 mt-2 font-medium mb-2"
//         >
//          Bank A/C No
//         </label>
//         <input
//           type="number"
//           id="bank_A/C"
//           value={amount}
//           onChange={(e) => setaccount_number(e.target.value)}
//           className="w-full border border-gray-400 p-2 rounded-lg"
//           placeholder="Enter Bank Account Number"
//         />
//         <button
//           onClick={handleWithdraw}
//           className="mt-6 bg-red-600 text-white py-2 px-6 rounded-full hover:bg-red-500 transition duration-200 w-full flex justify-center items-center"
//           disabled={loading}
//         >
//           {loading ? (
//             <AiOutlineLoading3Quarters
//               className="animate-spin mr-2"
//               size={18}
//             />
//           ) : (
//             "Withdraw"
//           )}
//         </button>
//       </div>
//     </div>
//   );
// }

// export default WithdrawModal;




import { useState } from "react";
import api from "../api";
import { FaTimes } from "react-icons/fa";
import { useAuth } from "../contexts/AuthContext";
import { useNavigate } from "react-router-dom";
import { AiOutlineLoading3Quarters } from "react-icons/ai";

function WithdrawModal({ onClose }) {
  const { token } = useAuth();
  const navigate = useNavigate();
  const [amount, setAmount] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");

  const [name, setName] = useState("");
  const [IFSC_CODE, setIFSC_CODE] = useState("");
  const [bankName, setBankName] = useState("");
  const [account_number, setaccount_number] = useState("");

  const validateForm = () => {
    if (!amount) {
      setError("Amount is required");
      return false;
    }
    if (!name) {
      setError("Name is required");
      return false;
    }
    if (!IFSC_CODE) {
      setError("IFSC IFSC_CODE is required");
      return false;
    }
    if (!bankName) {
      setError("Bank Name is required");
      return false;
    }
    if (!account_number) {
      setError("Bank Account Number is required");
      return false;
    }
    return true;
  };

  const handleWithdraw = async () => {
    if (!validateForm()) return;

    setLoading(true);
    setError("");
    setSuccess("");

    try {
      const response = await api.post(
        "/withdrawal/settled-payments/request-withdrawal",
        { 
          amount, 
          name, 
          IFSC_CODE, 
          bankName, 
          account_number 
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      setSuccess("Withdrawal request created successfully");
      onClose(); // Optional: close modal on success
    } catch (error) {
      console.error("Withdrawal error:", error);
      setError(error.response?.data?.message || "Something went wrong");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
      <div className="bg-white p-6 rounded-lg shadow-xl max-w-md w-full relative">
        <button
          onClick={onClose}
          className="absolute top-3 right-3 text-gray-400 hover:text-gray-600 focus:outline-none"
        >
          <FaTimes size={18} />
        </button>
        <h2 className="text-xl font-semibold mb-2 text-center text-gray-700">
          Enter Withdrawal Details
        </h2>
        <h2 className="text-sm font-semibold mb-4 text-center text-gray-700">
          Note: 5% TDS Deduction will be applied
        </h2>
        <div className="mb-4">
          {error && <p className="text-red-500 text-center">{error}</p>}
          {success && <p className="text-green-500 text-center">{success}</p>}
        </div>
        <label htmlFor="amount" className="block text-gray-700 font-medium mb-2">
          Amount
        </label>
        <input
          type="number"
          id="amount"
          value={amount}
          onChange={(e) => setAmount(e.target.value)}
          className="w-full border border-gray-400 p-2 rounded-lg"
          placeholder="Enter amount"
        />

        <label htmlFor="name" className="block text-gray-700 mt-2 font-medium mb-2">
          Name
        </label>
        <input
          type="text"
          id="name"
          value={name}
          onChange={(e) => setName(e.target.value)}
          className="w-full border border-gray-400 p-2 rounded-lg"
          placeholder="Enter name"
        />
        
        <label htmlFor="IFSC_CODE" className="block text-gray-700 mt-2 font-medium mb-2">
           IFSC_CODE
        </label>
        <input
          type="text"
          id="IFSC_CODE"
          value={IFSC_CODE}
          onChange={(e) => setIFSC_CODE(e.target.value)}
          className="w-full border border-gray-400 p-2 rounded-lg"
          placeholder="Enter IFSC IFSC_CODE"
        />
        
        <label htmlFor="bank_name" className="block text-gray-700 mt-2 font-medium mb-2">
          Bank Name
        </label>
        <input
          type="text"
          id="bank_name"
          value={bankName}
          onChange={(e) => setBankName(e.target.value)}
          className="w-full border border-gray-400 p-2 rounded-lg"
          placeholder="Enter Bank Name"
        />
        
        <label htmlFor="account_number" className="block text-gray-700 mt-2 font-medium mb-2">
          Bank A/C No
        </label>
        <input
          type="number"
          id="account_number"
          value={account_number}
          onChange={(e) => setaccount_number(e.target.value)}
          className="w-full border border-gray-400 p-2 rounded-lg"
          placeholder="Enter Bank Account Number"
        />
        
        <button
          onClick={handleWithdraw}
          className="mt-6 bg-red-600 text-white py-2 px-6 rounded-full hover:bg-red-500 transition duration-200 w-full flex justify-center items-center"
          disabled={loading}
        >
          {loading ? (
            <AiOutlineLoading3Quarters className="animate-spin mr-2" size={18} />
          ) : (
            "Withdraw"
          )}
        </button>
      </div>
    </div>
  );
}

export default WithdrawModal;